import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { User } from 'app/core/user/user.types';
import { appConfig } from 'app/core/config/app.config.dev';
import { Router, RouterState, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _router: Router,
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    // async get(): Promise<User> {
    //   //old api/common/user

    //   try {
    //     const user = await this._httpClient.get<any>(appConfig.basePath + "v0/user/me").pipe(
    //       tap((user) => {
    //         this._user.next(user);
    //       })
    //     ).toPromise();

    //     return user;
    //   } catch (error) {
    //     throw error;
    //   }

    //   // return this._httpClient.get<User>(appConfig.basePath + "v0/user/me").pipe(
    //   //   tap((user) => {
    //   //     // console.log(this._router.url);

    //   //     //Check if user is guest
    //   //     // if (this._router.url != "") {
    //   //     //   console.log(user);

    //   //     //   if (user.status == "GUEST") {
    //   //     //     if (this._router.url.includes("documents/prepare")) {
    //   //     //       console.log("Accepted");
    //   //     //     } else {
    //   //     //       console.log("logout\n============");

    //   //     //       // localStorage.setItem(
    //   //     //       //   "accessToken",
    //   //     //       //   "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiaWF0IjoxNjQ4NDY0ODk2LCJleHAiOjE2NDg0ODY0OTZ9.201mCFEoEcnzPqWo5nlPF_T9ZgSntjiSX5LYXtd04Og"
    //   //     //       // );

    //   //     //       // Redirect to the sign-in page
    //   //     //       // window. location. reload();
    //   //     //       this._user.next(user);
    //   //     //       this._router.navigateByUrl("/documents");
    //   //     //     }
    //   //     //   }
    //   //     // }

    //   //     this._user.next(user);
    //   //   })
    //   // );
    // }

    get(): Observable<User> {
        //old api/common/user
        return this._httpClient
            .get<User>(appConfig.basePath + 'admin/v0/dashboard/profile')
            .pipe(
                tap((user) => {
                    // console.log(this._router.url);

                    //Check if user is guest
                    // if (this._router.url != "") {
                    //   console.log(user);

                    //   if (user.status == "GUEST") {
                    //     if (this._router.url.includes("documents/prepare")) {
                    //       console.log("Accepted");
                    //     } else {
                    //       console.log("logout\n============");

                    //       // localStorage.setItem(
                    //       //   "accessToken",
                    //       //   "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiaWF0IjoxNjQ4NDY0ODk2LCJleHAiOjE2NDg0ODY0OTZ9.201mCFEoEcnzPqWo5nlPF_T9ZgSntjiSX5LYXtd04Og"
                    //       // );

                    //       // Redirect to the sign-in page
                    //       // window. location. reload();
                    //       this._user.next(user);
                    //       this._router.navigateByUrl("/documents");
                    //     }
                    //   }
                    // }

                    this._user.next(user);
                }),
            );
    }

    changeSignatureType(body): Observable<any> {
        return this._httpClient.post(
            appConfig.basePath + 'v0/user/signatureType',
            body,
        );
    }

    /**
     * @param formData
     */
    updateSignatureImage(
        fileToUpload: File,
        signatureType: string,
    ): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        formData.append('signatureType', signatureType);
        return this._httpClient.post(
            appConfig.basePath + 'v0/user/upload/signature',
            formData,
        );
        //old api/Settings/SignatureImage
        //new v0/user/upload/signature
    }

    getUserDetails(): Observable<any> {
        return this._httpClient.get(
            appConfig.basePath + 'user/v0/settings/profile'
        );
    }

    getSignatureImage(): Observable<any> {
        return this._httpClient.get(
            appConfig.basePath + 'user/v0/settings/signatures',
        );
    }

    getDigitalSignatureImage(): Observable<any> {
        return this._httpClient.get(
            appConfig.basePath + 'v0/user/getDummySignatureAppear',
        );
    }

    getSignerSignatureImage(email: string): Observable<any> {
        return this._httpClient.get(
            appConfig.basePath + 'v0/user/signatureImage/' + email,
        );
        //old api/Settings/SignatureImage
        //new v0/user/upload/signature
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient.patch<User>('api/common/user', { user }).pipe(
            map((response) => {
                this._user.next(response);
            }),
        );
    }

    getIPAddress(): Observable<any> {
        return this._httpClient.get(appConfig.basePath + 'v0/user/ip');
    }

    getIp(): Observable<any> {
        // http://ipv4.myexternalip.com/json
        // https://geolocation-db.com/json/
        return this._httpClient.get<any>(
            'https://www.cloudflare.com/cdn-cgi/trace',
        );
    }

    changeSignatureStatuforSelfSignSigner(
        signatureType: boolean,
        docID: string,
    ): Observable<any> {
        let body = {
            docId: docID,
            isDigitalSign: signatureType,
        };
        return this._httpClient.post(
            appConfig.basePath + 'v0/serviceProvider/isDigitalSign',
            body,
        );
    }
}
